import React from "react"
import ImageMeta from "../ImageMeta"

import { Transformation } from "cloudinary-react"

import ResidencyImageCaption from "./ResidencyImageCaption"
import TheaterVideo from "../Theater/TheaterVideo"

function SingleSlide(props) {
  let title = props.title
  if (title.charAt(0) !== "/") title = "/" + title

  return (
    <div>
      <TheaterVideo
        videoUrl={`https://www.youtube.com/watch?v=${props.youtube}`}
        playing={true}
        hasWatchVideo={false}
      >
        <ImageMeta
          cloudName="nuvolum"
          publicId={props.imageId}
          responsive
          responsiveUseBreakpoints="true"
          noLazyload
          className="residency-carousel-image"
        >
          <Transformation
            quality="auto"
            fetchFormat="auto"
            crop="scale"
            height="419"
          />
        </ImageMeta>
      </TheaterVideo>
      <ResidencyImageCaption
        reviewerName={props.name}
        reviewerTitle={props.title}
        reviewerYoutube={props.youtube}
      />
    </div>
  )
}

export default SingleSlide
