import React from "react"
import PropTypes from "prop-types"
import { getMonthYearFromStrings } from "../../utils/utils"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import TheaterVideo from "../Theater/TheaterVideo"

var classNames = require("classnames")

function ImageCaption(props) {
  let nameClasses = classNames("image-caption staff fixed-facewall-name", {
    last: !props.procedure && !props.monthYear,
  })
  let timeClasses = classNames("image-caption staff fixed-facewall-time", {
    last: true,
  })
  return (
    <TheaterVideo
      videoUrl={`https://www.youtube.com/watch?v=${props.reviewerYoutube}`}
      playing={true}
      hasWatchVideo={false}
    >
      <div className="image-caption-wrapper">
        <h6 className={nameClasses}>{props.reviewerName}</h6>
        <h6 style={{fontWeight: 400}} className={timeClasses}>{props.reviewerTitle}</h6>
      </div>
    </TheaterVideo>
  )
}

ImageCaption.propTypes = {
  reviewerName: PropTypes.string,
  language: PropTypes.string,
  procedure: PropTypes.string,
  procedureEs: PropTypes.string,
  hideProcedureNames: PropTypes.bool,
  monthYear: PropTypes.object,
}

export default ImageCaption
