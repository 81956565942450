import React, { useRef, useState, useEffect } from "react"
import Slider from "react-slick"
import ImageMeta from "../ImageMeta"
import TheaterVideo from "../../components/Theater/TheaterVideo"

const QuoteSlides = ({ quoteSlides }) => {
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const slider1 = useRef(null)
  const slider2 = useRef(null)
  let slider2Dots = []

  useEffect(() => {
    setNav1(slider1.current)
    setNav2(slider2.current)
  }, [])

  const [imageIndex, setImageIndex] = useState(0)

  const slider1Settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 8000,
    cssEase: "ease",
    focusOnSelect: true,
    arrows: false,
    swipeToSlide: true,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const slider2Settings = {
    arrows: true,
    slidesToShow: 1,
    dots: true,
    pauseOnDotsHover: true,
    pauseOnHover: true,
  }

  const quoteSlideNum = quoteSlides.length

  for (let i = 1; i <= quoteSlideNum; i++) {
    slider2Dots.push(<div key={i}></div>)
  }

  if (quoteSlideNum === 3 || quoteSlideNum === 2) {
    quoteSlides = quoteSlides.concat(quoteSlides)
    slider2Dots = slider2Dots.slice(0, 3)
  }

  return (
    <div style={{ padding: "40px 0 80px", backgroundColor: "#f7f7f7" }}>
      <div>
        <Slider {...slider1Settings} asNavFor={nav2} ref={slider1}>
          {quoteSlides.map((quote, i) => (
            <QuoteSlide
              key={i}
              quoteText={quote.quote}
              quoteName={quote.name}
              quotePracticeName={quote.practiceName}
              quoteImageId={quote.imageId}
              quoteYoutube={quote.youtube}
              className={
                i === imageIndex
                  ? "activeSlide quote-slide-box"
                  : "quote-slide-box"
              }
            />
          ))}
        </Slider>
        <div className="slick-arrow-track">
          <Slider
            className="bottom-quote-slider"
            {...slider2Settings}
            asNavFor={nav1}
            ref={slider2}
          >
            {slider2Dots}
          </Slider>
        </div>
      </div>
    </div>
  )
}

function QuoteSlide(props) {
  return (
    <div key={props.key} className={props.className}>
      <div className="quote-slide-quote">
        <p>&ldquo;{props.quoteText}&rdquo;</p>
        <div className="quote-slide-bottom">
          <ImageMeta
            cloudName="nuvolum"
            publicId={props.quoteImageId}
            width="auto"
            className=""
          />
          <div className="quote-slide-name">
            <h6>{props.quoteName}</h6>
            {/* <p>{props.quotePracticeName}</p> */}
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${props.quoteYoutube}`}
                controls={true}
                playing={true}
                onlyButton={true}
                language={"en"}
                // buttonClass="small-theater-button"
                buttonClass="contained"
              />
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuoteSlides
