import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { shuffleArray } from "../utils/utils"

import GetStarted from "../components/CTA/GetStarted"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../components/ImageMeta"
import TheaterVideo from "@components/Theater/TheaterVideo"
import FixedFacewall from "../components/carousel/FixedFacewall"
import QuoteSlides from "../components/Sliders/QuoteSlides"
import ResidencyCarousel from "../components/carousel/ResidencyCarousel"
import { GridGallery, DialogGallery } from "./singleCommunity"
import { Dialog } from "@blueprintjs/core"
import CloseIcon from "../components/CloseIcon"
import ImageGallery from "react-image-gallery"
import { getClientCloudinaryMedia } from "../utils/utils"

const Residency = ({ data, pageContext, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const language = pageContext.language

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [galleryIndex, setGalleryIndex] = useState(0)

  const toggleDialog = (prop) => {
    setIsDialogOpen(!isDialogOpen)
    setGalleryIndex(prop)
  }

  let photos = {}
  var displayedPhotos = []

  if (post.gallery.prefix) {
    photos = getClientCloudinaryMedia(false, post.gallery.prefix, "media")
    displayedPhotos = photos
  }

  var reactGalleryPhotos = function (photos) {
    let arr = []
    for (let i = 0; i < photos.length; i++) {
      let obj = {}
      obj.original = `https://res.cloudinary.com/nuvolum/image/upload/q_60,w_1024/v1/${photos[i]}.jpg`
      arr.push(obj)
    }
    return arr
  }

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />

        <div className="residency-page">
          <div className="columns residency-top">
            <div className="column is-3"></div>
            <div className="column">
              <h1>{post.heading}</h1>
              <MarkdownViewer markdown={post.body} />
              <br />
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                overVideo={true}
                language={language}
                buttonClass="contained"
              >
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={post.imageId}
                  width="auto"
                  className="theater-image"
                ></ImageMeta>
              </TheaterVideo>
            </div>
            <div className="column is-3"></div>
          </div>

          <div className="columns residency-body color-back">
            <div className="column is-3"></div>
            <div className="column">
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.asird.logo}
                width="auto"
                className="asird-logo"
              ></ImageMeta>
              <MarkdownViewer markdown={post.asird.body} />
            </div>
            <div className="column is-3"></div>
          </div>

          <div
            className="residency-body color-back has-text-centered"
            style={{ paddingTop: 0 }}
          >
            <div className="columns">
              <div className="column is-3" />
              <MarkdownViewer markdown={post.reviewsHeading} />
              <div className="column is-3" />
            </div>
            <ResidencyCarousel
              id="singleReview"
              noShuffle={false}
              relatedReviews={post.reviews}
            />
          </div>

          <section className="residency-body">
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column">
                <div className="columns">
                  <div className="column">
                    <div className="is-hidden-mobile">
                      <MarkdownViewer markdown={post.textImages[0].body} />
                      <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${post.textImages[0].youtube}`}
                        overVideo={false}
                        language={language}
                        buttonClass="contained"
                      />
                    </div>
                    <div className="is-hidden-tablet">
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={post.textImages[0].imageId}
                        width="auto"
                      />
                    </div>
                  </div>
                  <div className="column is-2"></div>
                  <div className="column">
                    <div className="is-hidden-mobile">
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={post.textImages[0].imageId}
                        width="auto"
                      />
                    </div>
                    <div className="is-hidden-tablet">
                      <MarkdownViewer markdown={post.textImages[0].body} />
                      <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${post.textImages[0].youtube}`}
                        overVideo={false}
                        language={language}
                        buttonClass="contained"
                      />
                    </div>
                  </div>
                </div>
                <div className="columns residency-padding" />
                <div className="columns">
                  <div className="column">
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={post.textImages[1].imageId}
                      width="auto"
                      className=""
                    />
                  </div>
                  <div className="column is-2"></div>
                  <div className="column">
                    <MarkdownViewer markdown={post.textImages[1].body} />
                    <TheaterVideo
                      videoUrl={`https://www.youtube.com/watch?v=${post.textImages[1].youtube}`}
                      overVideo={false}
                      language={language}
                      buttonClass="contained"
                    />
                  </div>
                </div>
                <div className="columns residency-padding" />
                <div className="columns residency-banner">
                  <div className="column is-2"></div>
                  <div className="column">
                    <div className="columns residency-padding" />
                    <MarkdownViewer markdown={post.bannerText} />
                    <div className="columns residency-padding" />
                  </div>
                  <div className="column is-2"></div>
                </div>
                <div className="columns residency-padding" />
                <div className="column">
                  <MarkdownViewer
                    markdown={post.bottomVideo.heading}
                    className="hast-text-centered"
                  />
                  <br />
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${post.bottomVideo.youtube}`}
                    overVideo={true}
                    language={language}
                    buttonClass="contained"
                  >
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={post.bottomVideo.imageId}
                      width="auto"
                      className="theater-image"
                    ></ImageMeta>
                  </TheaterVideo>
                </div>
              </div>
              <div className="column is-3"></div>
            </div>
          </section>

          <section className="residency-body">
            <div className="columns has-text-centered">
              <div className="column">
                <h3>Gallery</h3>
                <GridGallery
                  pages={displayedPhotos}
                  rowsOf={3}
                  toggleDialog={toggleDialog}
                />
              </div>
            </div>
          </section>

          <QuoteSlides quoteSlides={post.quoteSlides} />

          <GetStarted
            className="residency-cta color-back"
            sideColumnIs={2}
            centerHeading
            centerText
            heading={post.getStarted.heading}
            buttons={post.getStarted.buttons}
            paragraph={<p>{post.getStarted.blurb}</p>}
          />

          <div>
            <Dialog
              className="community-dialog"
              lazy={false}
              backdropClassName="dark-background"
              portalClassName="show-cursor community-portal"
              isOpen={isDialogOpen}
              onClose={toggleDialog}
            >
              <CloseIcon onClick={toggleDialog} />
              <DialogGallery
                galleryIndex={galleryIndex}
                images={reactGalleryPhotos(displayedPhotos)}
              />
            </Dialog>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query ResidencyPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        youtube
        imageId
        body
        facewall {
          heading
        }
        asird {
          logo
          body
        }
        textImages {
          body
          youtube
          imageId
        }
        bannerText
        bottomVideo {
          heading
          youtube
          imageId
        }
        quoteSlides {
          youtube
          quote
          imageId
          name
          practiceName
        }
        reviews {
          name
          title
          imageId
          youtube
        }
        reviewsHeading
        gallery {
          prefix
        }
        getStarted {
          buttons {
            button {
              appearance
              buttonText
              href
            }
          }
          heading
          blurb
        }
      }
    }
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Residency" }, reviewLanguage: { eq: "ENG" } }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        monthYear {
          month
          year
        }
      }
    }
  }
`

export default Residency
